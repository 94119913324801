.sidebar_body{
    width: 27%;
    position: relative;
    padding: 0
}
.sidebar_search{
    width: 100%;
    height: 88%;
    max-width: 100vh;
    display: flex;
    position: relative;
}
.sidebar_profile{
    width: 100%;
    height: 92%;
    max-width: 100vh;
    
    display: flex;
    position: relative;
}

.sidebar_bottom{
    /* bottom of sidebar */

    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #f7f7f7;
    border-top: 1;
    border-color: divider;
    left:0;
}

.sidebar_bottom_button{
    width: 50%;
    font-size: 12px
}
