:root {
  --rad: .7rem;
  --dur: .3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #57bd84;
  --font-fam: "Lato", sans-serif;
  --height: 5rem;
  --btn-width: 6rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


.searchInputs input {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #2f2f2f;
  font-size: 18px;
  padding: 15px;
  background-color: #f7f7f7;
  height: 28px;
  border-top: 1px rgb(80, 79, 79) solid;
  outline: #2a95a5;
  width: 17vw;
}

.searchInputs input:focus {
  border: 1.2px solid #35c2d8;

  outline: #2a95a5;
}

.searchInputs input::placeholder {
  font-size: 14px;
}

.search {
  width: 100%;
  display: flex;

  flex-direction: column;

}

.searchBody {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -0.8rem;

}

.sidebar_message {
  position: relative;
  margin-top: 15px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  position: absolute;
  max-height: 77vh;
  top: 10rem;

}

.sidebar_message:hover {
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;

}

/* ===== Scrollbar CSS ===== */
/* Firefox */
body {
  scrollbar-width: auto;
  scrollbar-color: #121212 #fefbfb;
  z-index: 400;
}

/* Chrome, Edge, and Safari */
.sidebar_message::-webkit-scrollbar {
  width: 5px;
}

.sidebar_message::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  opacity: 0.5;
}

.sidebar_message::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: transparent;
}

.searchIcon {
  height: 58px;
  background-color: #f7f7f7;

  position: relative;

  border-left: 0px;
  cursor: pointer;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  display: grid;
  place-items: center;
}

#clearBtn {
  cursor: pointer;
}

.searchInputs {

  display: flex;

  width: 100%;

}


.search-user-loader {
  display: flex;
  flex-direction: row;
}